.padding {
	padding: 4rem 0;
}
.center {
    margin: 0 auto;
}
.error {
	color: red;
	padding-bottom: 1em;
}
